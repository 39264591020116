import React from 'react'
import Title from 'components/title'
import Paragraph from 'components/paragraph'
import { SubTitle } from 'components/policies/styles'

const Returns = () => (
  <article>
    <Title is="h1">Returns &amp; Exchanges</Title>
    <Paragraph>
      When ordering from Evilneon, you assume the responsibility for your
      purchase, and no refunds will be issued except under the conditions
      provided below:
    </Paragraph>
    <SubTitle>Originals</SubTitle>
    <Paragraph>
      All framed, original art pieces are sold "as is". No Returns nor exchanges
      will be made unless the item is damaged in transit. In these unfortunate
      siutations, we will investigate the incident, and, when valid, will refund
      your purchase and file a claim with the shipper directly.
    </Paragraph>
    <SubTitle>Prints</SubTitle>
    <Paragraph>
      If we made a mistake and sent you the wrong print, we will exchange the
      artwork at no additional cost, provided that the returned print is not
      damaged. In the event your package is damaged in transit, we will
      investigate the incident, and, when valid, will refund your purchase and
      file a claim with the shipper directly.
    </Paragraph>
    <SubTitle>Commissions &amp; Edits</SubTitle>
    <Paragraph>
      Once your portrait is sent to Evilneon, we retain full artist freedom over
      the end product. Because our edits are a custom, time-intestive process,
      no refunds will be made.
    </Paragraph>
  </article>
)

export default Returns
