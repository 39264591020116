import React from 'react'
import Privacy from 'components/policies/privacy'
import Returns from 'components/policies/returns'
import { Wrap } from 'components/policies/styles'

const Policies = ({ policy }) => {
  const PolicyElement = {
    returns: Returns,
    privacy: Privacy,
  }[policy]

  return (
    <Wrap>
      <PolicyElement />
    </Wrap>
  )
}

export default Policies
