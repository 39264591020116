import styled from 'styled-components'
import { rem } from 'styled-tidy'
import media from 'lib/styles/media'
import BaseWrap from 'components/wrap'

export const Wrap = styled(BaseWrap)`
  &&& {
    padding: ${rem(16)} ${rem(16)} ${rem(64)} !important;
  }

  ${media.medium`
    article {
      max-width: ${rem(640)};
    }
  `}
`

export const SubTitle = styled.h3`
  font-size: ${rem(14)};
  font-weight: 600;
  padding: ${rem(32)} 0 ${rem(8)};
  text-align: left;
`
