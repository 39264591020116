import React from 'react'
import Layout from 'layouts/default'
import Policies from 'components/policies'

const Returns = () => (
  <Layout path="/policies/returns" subheader="policies">
    <Policies policy="returns" />
  </Layout>
)

export default Returns
