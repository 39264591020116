import React from 'react'
import styled from 'styled-components'
import { rem } from 'styled-tidy'
import theme from 'lib/styles/theme'

const { iron, black } = theme.colors

const List = ({ is, align, children, ...rest }) => {
  const Element = styled(is || 'ul')`
    align-items: center;
    color: ${iron};
    font-size: ${rem(14)};
    font-weight: 200;
    line-height: 1.2;
    margin: 0;
    padding: ${rem(8)} 0;
    text-align: ${align || 'left'};
    width: 100%;

    li {
      list-style: ${is === 'ol' ? 'decimal' : 'square'};
      margin-left: ${rem(32)};
    }

    a {
      color: ${black};
      margin: 0 ${rem(4)};
      text-decoration: underline;
    }
  `

  return <Element {...rest}>{children}</Element>
}

export default List
